import * as React from 'react'
import LayoutWork from '../../components/layoutWork'
import {Container, Row, Col} from "react-bootstrap" 
import head from '../../images/portfolio/pitapat/pit-a-pat-website-video.mp4'
import shape1 from '../../images/portfolio/pitapat/shape1.mp4'
import shape2 from '../../images/portfolio/pitapat/shape2.mp4'
import cinekid1 from '../../images/portfolio/pitapat/cinekid1.jpg'
import cinekid2 from '../../images/portfolio/pitapat/cinekid2.jpg'
import shapes from '../../images/portfolio/pitapat/shapes.png'
import { Link } from 'gatsby'

const PortfolioPitPage = () => {
  return (
    <LayoutWork pageTitle="Pit.a.pat">
      <Container><p className='info'>Hybrid interactive installation | With | <Link to='https://arimit.xyz/'> Arimit Bhattacharya</Link>, <Link to='https://davidjonas.art/'>David Jonas</Link> &#38; <Link to='http://www.edenmitsenmacher.net/'>Eden Mitsenmacher</Link> | For | <Link to='https://cinekid.nl/'> Cinekid Festival</Link></p></Container><hr/>
      <Container>
        <br/>
        <h1>Pit.a.pat</h1>
        <h2>Pit.a.pat is a listening landscape created for the Cinekid MediaLab. Move around the room, click, hold and drag the shapes. But above all listen very carefully! (preferably with headphones) </h2>
        <br/>
        <video src={head} width="100%" height="auto" autoplay="true" loop><track kind="captions" srcLang="en" label="English" default /></video>
        <br/>
        <br/>
        <p>Pit.a.pat is a unique listening landscape that was designed for the Cinekid MediaLab. This interactive experience encourages visitors to move around the room and click, hold, and drag various shapes. However, the main focus of the experience is listening carefully to the sounds that surround you. We recommend using headphones to fully immerse yourself in this auditory journey.</p>
        <br/>
        <p>The internet is home to many unusual videos, but in recent years, an increasing number of ASMR (Autonomous Sensory Meridian Response) videos have emerged. These videos feature soft, subtle sounds that stimulate the brain and create a sense of relaxation. With Pit.a.pat, users can combine various ASMR sounds to create a unique and customized auditory experience that is both strange and unexpectedly pleasant.</p>
        <Row>
          <Col sm={6}>
              <Row>
                <video src={shape1} width="100%" height="auto" autoplay="true" loop><track kind="captions" srcLang="en" label="English" default /></video>
              </Row>
              <br/>
              <br/>
          </Col>
          <Col sm={6}>
              <Row>
                <video src={shape2} width="100%" height="auto" autoplay="true" loop><track kind="captions" srcLang="en" label="English" default /></video>
              </Row>
              <br/>
          </Col>
          <p>Originally designed as a physical installation for the Cinekid festival in 2020, Pit.a.pat has since been adapted for online use due to the pandemic. Visitors can still explore the auditory landscape at pitapat.org, where they can experiment with different sounds and create their own personalized listening experience.</p>
          <Col sm={6}>
              <Row>
                <img src={cinekid1} className="Kids interacting with work" alt="thumbnail"/>
              </Row>
              <br/>
              <br/>
          </Col>
          <Col sm={6}>
              <Row>
                <img src={cinekid2} className="Other kids interacting with work" alt="thumbnail"/>
              </Row>
          </Col>
        </Row>
        <br/>
        <img src={shapes} className="fullimg" alt="All shapes of the hybrid interactive installation "/>
      </Container>
    </LayoutWork>
    
  )
}
export default PortfolioPitPage